import { BACKEND_URL } from '@src/config';


export default class ApiClient {

  constructor(userManager) {
    this.userManager = userManager;
    this.apiUrl = BACKEND_URL;
  }

  async getAccessToken() {
    const { access_token } = await this.userManager.getUser();
    return access_token;
  }

  async fetch(endpoint, opts={}) {
    const url = this.apiUrl + endpoint;
    const accessToken = await this.getAccessToken();
    const options = {
      ...opts,
      headers: {
        ...opts.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return await fetch(url, options);
  }

  async getConfig(key) {
    return await this.fetch(`/configs/${key}`);
  }

  async createOrUpdateConfig(key, value) {
    const existsResp = await this.getConfig(key);
    const exists = existsResp.status == 200;
    const method = exists ? 'PUT'  : 'POST';
    const endpoint = exists ? `/configs/${key}` : `/configs/`;
    return await this.fetch(endpoint, {
      method,
      body: JSON.stringify({
        key,
        value,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}

