import React, { useCallback, useEffect, useState, useMemo } from 'react';

import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { debounce } from '@mui/material/utils';

import useCallApi from '@src/hooks/useCallApi';
import ExtentMap from '@src/components/forms/ExtentMap';
import SimpleMap from '@src/components/SimpleMap';


async function readFile(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = ({ target }) => {
      const { result } = target;
      resolve(result);
    };
    reader.onerror = () => {
      reject("Erreur lors de la lecture du fichier");
    };
    reader.readAsText(file);
  });
}


async function parseJsonFile(file) {
  const text = await readFile(file);
  return JSON.parse(text);
}


function validateZones(content) {
  const isCollection = content?.type === 'FeatureCollection' && content.features.length;
  if (!isCollection) {
    return false;
  }

  for (const feature of content.features) {
    if (!feature?.geometry?.type === 'MultiPolygon') {
      return false;
    }
  }

  return {
    ...content,
    features: content.features.map((feature, index) => ({
      ...feature,
      properties: {
        title: `Zone ${index + 1}`,
        ...feature.properties,
      }
    }))
  };
}

function validateExtent(content) {
  return (
    content?.type === 'Feature' && (
      content?.geometry?.type === 'Polygon' ||
      content?.geometry?.type === 'MultiPolygon'
    )
  ) ? content : false;
}

function formatCity({ nom='', codeDepartement, codesDepartements=[] }) {
  const departement = codeDepartement ?? codesDepartements.join(', ');
  return `${nom} (${departement})`;
}

const EXTENT_API_URL = 'https://geo.api.gouv.fr/';

function SearchExtentForm({
  apiName,
  onChange,
  validateContent,
  label="Rechercher",
}) {
  const [ isSearching, setIsSearching ] = useState(false);
  const [ input, setInput ] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);
  const [code, setCode] = useState('');

  const search = async (query) => {
    const params = new URLSearchParams({ nom: query, limit: 20 });
    const url = `${EXTENT_API_URL}${apiName}?${params.toString()}`;
    const resp = await fetch(url);
    const results = await resp.json();
    setResults(results);
    setCode('');
    setIsSearching(false);
  }

  const searchDebounce = useMemo(() => debounce(search, 2000), []);

  const getGeoJSON = async (code) => {
    const params = new URLSearchParams({
      code,
      geometry: 'contour',
      format: 'geojson',
    });
    const url = `${EXTENT_API_URL}${apiName}?${params.toString()}`;
    const resp = await fetch(url);
    const result = await resp.json();
    if (result.features?.length) {
      const feature = result.features[0];
      const content = {
        ...feature,
        properties: {
          ...feature.properties,
          title: formatCity(feature.properties),
        },
      };
      try {
        const isValid = validateContent(content)
        if (!isValid) {
          throw "GeoJSON non conforme";
        }
        onChange({
          content, isComplete: true,
        });
      } catch (err) {
        onChange({
          error: err, isComplete: false,
        });
      }
    }
  }
  
  const onInputChange = (event) => {
    const value = event?.target?.value ?? '';
    setInput(value);
    setSearchQuery(value);
  }

  const onSelected = (e, selected={}) => {
    setCode(selected?.code ?? '');
    setInput(selected ? formatCity(selected) : '');
  }

  useEffect(() => {
    if (searchQuery.length >=3) {
      setIsSearching(true);
      searchDebounce(searchQuery);
    }
    return setResults([]);
  }, [searchQuery]);

  useEffect(() => {
    if (code) {
      getGeoJSON(code);
    }
  }, [code]);


  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Autocomplete
          freeSolo
          options={results}
          getOptionLabel={formatCity}
          inputValue={input}
          onInputChange={onInputChange}
          onChange={onSelected}
          renderInput={(params) => (
            <TextField
              {...params} label={label} variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isSearching ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
          }}
            />
          )}
        />
      </Box>
    </Box>
  );
}



function UploadGeoJSONForm({
  file,
  content,
  error,
  isComplete=false,
  onChange,
  validateContent,
}) {
  const onFile = async ({ target }) => {
    const { files } = target;
    if (files.length >= 0) {
      const file = files[0];
      try {
        const content = await parseJsonFile(file);
        const validContent = validateContent(content)
        if (!validContent) {
          throw "GeoJSON non conforme";
        }
        onChange({
          file, content: validContent, isComplete: true,
        });
      } catch (err) {
        onChange({
          file, error: err, isComplete: false,
        });
      }
    }
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
      <Button variant="contained" component="label" color={error ? 'error' : 'primary'}>
        Choisir un fichier
        <input
          type="file"
          hidden
          accept="application/json, application/geo+json, .json, .geojson"
          onChange={onFile}
        />
      </Button>
      {file?.name ? (
        <Typography sx={{ margin: 1 }} color={error ? 'error' : 'success' }>
          { file?.name || '' } ({ file?.type })
        </Typography>
      ) : null}
      {error ? (
        <Typography sx={{ margin: 1 }} color="error">
          { `${error}` }
        </Typography>
      ) : null}
    </Box>
  );
}


export default function WorkingZonesStep({
  wizard={},
  updateWizard,
  isComplete=false,
  setIsComplete,
  setError,
}) {

  const instanceExtent = useCallApi('/configs/INSTANCE_EXTENT');
  const instanceWorkingZones = useCallApi('/working-zones/');

  const { zones={}, extent={} } = wizard;

  const [ extentType, setExtentType ] = useState('commune');

  const onChangeExtent = ({ file, content, error, isComplete }) => {
    updateWizard({
      ...wizard,
      extent: { file, content, error, isComplete },
    });
    setIsComplete(isComplete || zones.isComplete);
    setError(!!error || zones.error);
  }
  
  const onChangeZones = ({ file, content, error, isComplete }) => {
    updateWizard({
      ...wizard,
      zones: { file, content, error, isComplete },
    });
    setIsComplete(isComplete || extent.isComplete);
    setError(!!error || extent.error);
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography>
        Cette étape permet de définir une emprise et des zones de travail pour le projet.
      </Typography>
      <Box sx={{ pt: 2 }}>
        <Typography variant="h2">
          Emprise
        </Typography>
        <Typography paragraph>
          L'emprise correspond la commune ou l'EPCI sur laquelle aura lieu le relevé.
          Le choix d'une emprise permet d'automatiser les imports de données OpenStreetMap le
          cas échéant.
        </Typography>
        <Typography paragraph>
          Il est possible de choisir une commune ou un EPCI par son nom, ou bien d'importer
          un fichier GeoJSON.
        </Typography>
        {instanceExtent?.value ? (
          <Alert severity="info">
            Une emprise est déja définie pour cette instance. Vous pouvez la modifier ci dessous.
          </Alert>
        ) : null}
        <Tabs value={extentType} onChange={(_, value) => setExtentType(value)}>
          <Tab
            value="commune"
            label="Commune"
            sx={{ textTransform: 'none' }}
          />
          <Tab
            value="epci"
            label="EPCI"
            sx={{ textTransform: 'none' }}
          />
          <Tab
            value="geojson"
            label="Fichier"
            sx={{ textTransform: 'none' }}
          />
        </Tabs>
        <Box sx={{ p: 2 }}>
          {extentType == 'commune' ? (
            <SearchExtentForm
              apiName='communes'
              onChange={onChangeExtent}
              validateContent={validateExtent}
              label="Rechercher une commune"
            />
          ) : null}
          {extentType == 'epci' ? (
            <SearchExtentForm
              apiName='epcis'
              onChange={onChangeExtent}
              validateContent={validateExtent}
              label="Rechercher un EPCI"
            />
          ) : null}
          {extentType == 'geojson' ? (
            <>
              <Typography paragraph>
                Fichier GeoJSON contenant une Feature de type MultiPolygon.
              </Typography>
              <UploadGeoJSONForm
                {...extent}
                onChange={onChangeExtent}
                validateContent={validateExtent}
              />
            </>
          ) : null}
        </Box>
        <ExtentMap
          extent={extent?.content || instanceExtent?.value }
          defaultExpanded={false}
        />
      </Box>
      <Box sx={{mt: 4}}>
        <Typography variant="h2">
          Zones de travail
        </Typography>
        <Typography paragraph>
          Les zones de travail sont les zones au sein de l'emprise sur lesquelles le relevé
          va être réalisé en priorité. Il peut s'agit par exemple de zones autour de chaque
          arrêt de transport prioritaire.
        </Typography>
        <Typography paragraph>
          Pour définir des zones de travail, il faut importer un fichier au format GeoJSON contenant une feature collection de type MultiPolygon.
        </Typography>
        {instanceWorkingZones?.features.length ? (
          <Alert severity="info">
            Des zones de travail sont déjà définies pour cette instance.
            Vous pouvez les modifier ci dessous.
          </Alert>
        ) : null}
        <UploadGeoJSONForm
          {...zones}
          onChange={onChangeZones}
          validateContent={validateZones}
        />
        <>
          {zones?.content ? (
            zones.content.features?.map((feature, index) => (
              <Accordion TransitionProps={{ unmountOnExit: true }} key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>
                    { feature?.properties.title ?? `Zone ${index + 1}` }
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SimpleMap features={feature} static/>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            instanceWorkingZones?.features?.map((feature, index) => (
              <Accordion TransitionProps={{ unmountOnExit: true }} key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>
                    { feature?.properties.title ?? `Zone ${index + 1}` }
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SimpleMap features={feature} static/>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </>
      </Box>
    </Box>
  );
}
