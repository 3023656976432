import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CrossIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import useCallApi from '@src/hooks/useCallApi';
import useApiClient from '@src/hooks/useApiClient';
import DocLink from '@src/components/forms/DocLink';
import useAsyncStatus from '@src/hooks/useAsyncStatus';


function useGetLastImport(key) {
  const [ lastImport, setLastImport ] = useState();
  const api = useApiClient();
  useEffect(() => {
    if (api.current) {
      const foo = async () => {
        const config = await (await api.current.fetch(key)).json();
        const importId = config?.value?.import_id;
        if (importId) {
          const dataImport = await (await api.current.fetch(`/data-imports/${importId}`)).json();
          setLastImport(dataImport)
        }
      };
      foo();
    }
  }, [api.current, key]);
  return lastImport;
}


function ImportChip({ dataImport, label }) {
  const api = useApiClient();
  const imp = useAsyncStatus(api.current, `/data-imports/${dataImport.id}`, dataImport);
  const { status } = imp;

  const Component = {
    SUCCESS: (
      <Chip color="success" icon={<CheckIcon/>} label={`${label} réalisé`} />
    ),
    ERROR: (
      <Chip color="error" icon={<CrossIcon/>} label={`${label} échoué`} />
    ),
    PENDING: (
      <Chip icon={<CircularProgress size={20}/>} label={`${label} en cours`} />
    ),
    RUNNING: (
      <Chip icon={<CircularProgress size={20}/>} label={`${label} en cours`} />
    ),
  }[status];

  return Component;
}


export default function ConfigHome() {

  const instanceExtent = useCallApi('/configs/INSTANCE_EXTENT');
  const instanceWorkingZones = useCallApi('/working-zones/');
  const dataLicense = useCallApi('/configs/DATA_LICENSE');
  const lastOsmImport = useGetLastImport('/configs/LAST_OSM_IMPORT');
  const lastNeTExImport = useGetLastImport('/configs/LAST_NETEX_IMPORT');

  return (
    <Box sx={{ m: 4}}>
      <Typography paragraph>
        La configuration de l'instance consiste à définir l'emprise et les zones de travail prioritaires, ainsi
        que d'importer des données initiales.
      </Typography>
      <Box sx={{ m: 4 }}>
        <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
          {instanceExtent?.value ? (
            <Box sx={{ m: 1 }}>
              <Chip icon={<CheckIcon/>} color="success" label="Emprise configurée"/>
            </Box>
          ) : null}
          {instanceWorkingZones?.features?.length ? (
            <Box sx={{ m: 1 }}>
              <Chip icon={<CheckIcon/>} color="success" label="Zones de travail configurées"/>
            </Box>
          ) : null}
          {lastOsmImport ? (
            <Box sx={{ m: 1 }}>
              <ImportChip dataImport={lastOsmImport} label="Import OpenStreetMap"/>
            </Box>
          ) : null}
          {lastNeTExImport ? (
            <Box sx={{ m: 1 }}>
            <ImportChip dataImport={lastNeTExImport} label="Import NeTEx"/>
            </Box>
          ) : null}
          {dataLicense?.value ? (
            <Box sx={{ m: 1 }}>
              <Chip icon={<CheckIcon/>} color="success" label="Licence configurée"/>
            </Box>
          ) : null}
        </Stack>
      </Box>
      <Alert severity="warning">
        <Typography>
          Attention : réaliser des imports de données alors que des données existent déjà
          sur l'instance peut mener à des doublons.
        </Typography>
        <Typography>
          <DocLink path="/usage/imports/">
            Voir la documentation  en ligne.
          </DocLink>
        </Typography>
      </Alert>
      <Box sx={{ p: 2 }}>
        <Typography>
          <Button variant="contained" component={Link} to="wizard">
            Démarrer la configuration
          </Button>
        </Typography>
      </Box>
    </Box>
  );
}
