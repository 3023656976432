import React, { useRef, useEffect } from 'react';
import turfBbox from '@turf/bbox';

import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';


const style = {
  'version': 8,
  'sources': {
    'raster-tiles': {
      'type': 'raster',
      'tiles': [
        'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      ],
      'tileSize': 256,
      'attribution': '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    },
  },
  'layers': [{
    'id': 'simple-tiles',
    'type': 'raster',
    'source': 'raster-tiles',
    'minzoom': 0,
    'maxzoom': 22
  }],
};



export default function SimpleMap({
  features,
  static=false,
}) {
  const container = useRef();
  const map = useRef();
  useEffect(() => {
    const bbox = turfBbox(features);
    map.current = new maplibregl.Map({
      container: container.current,
      style: style,
      bounds: bbox,
      fitBoundsOptions: {
        padding: 5,
      },
      interactive: false,
    });
    map.current.once('load', function () {
      console.log('load');
      map.current.addSource('maine', {
        'type': 'geojson',
        'data': features,
      });
      map.current.addLayer({
        'id': 'maine',
        'type': 'fill',
        'source': 'maine',
        'layout': {},
        'paint': {
          'fill-color': '#088',
          'fill-opacity': 0.8
        }
      });
    });
    return () => {
      map.current.remove();
      map.current = null;
    }
  }, [features]);
  return (
    <div ref={container} style={{ width: '100%', minHeight: 320 }}>
    </div>
  );

}
