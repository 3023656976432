import React, { useState, useEffect } from 'react';

function pollData(api, endpoint, initial) {
  const isFinished = ({ status }) => ['SUCCESS', 'ERROR'].includes(status);
  let interval = null;
  const start = () => new Promise((resolve, reject) => {
    if (isFinished(initial)) {
      resolve(initial);
    } else {
      interval = setInterval(async () => {
        const res = await (await api.fetch(endpoint)).json();
        if (isFinished(res)) {
          clearInterval(interval);
          resolve(res);
        }
      }, 2000);
    }
  });
  const clear = () => clearInterval(interval);
  return { start, clear };
}

export default function useAsyncStatus(api, endpoint, initial) {
  const [exp, setExp] = useState(initial);
  const poller = pollData(api, endpoint, initial);
  useEffect(() => {
    if (api) {
      const updateStatus = async () => {
        const newExp = await poller.start();
        setExp(newExp);
      };
      updateStatus();
    }
    return poller.clear;
  }, [api, endpoint, initial]);
  return exp;
}

