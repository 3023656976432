import React, { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import * as filetypes from '@src/filetypes';
import useCallApi from '@src/hooks/useCallApi';
import { uploadDisplayName } from '@src/helpers';
import FileUploadForm from '@src/components/forms/FileUploadForm';
import DocLink from '@src/components/forms/DocLink';
import ExtentMap from '@src/components/forms/ExtentMap';



export default function PointsOfInterestStep({
  wizard={},
  updateWizard,
  isComplete=false,
  setIsComplete,
  setError,
}) {

  const instanceExtent = useCallApi('/configs/INSTANCE_EXTENT');

  const { poi={}, extent={} } = wizard;
  const { osm=false} = poi;

  const onChange = ({ target }) => {
    updateWizard({
      ...wizard,
      poi: {
        ...poi,
        osm: target.checked,
      },
    });
    setIsComplete(!!target.checked)
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography>
        L'import des points d'intérêts permet d'initialiser la base de données
        d'AccesLibre Mobilités avec des ERP (établissement recevant du public).
      </Typography>
      <Typography>
        <DocLink path="/usage/imports/ERP/">
          Voir la documentation en ligne
        </DocLink>
      </Typography>
      <Box sx={{ pt: 2}}>
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              checked={osm}
              onChange={onChange}
            />
          }
          label="Importer automatiquement les données OpenStreetMap de l'emprise"
          labelPlacement="end"
        />
      </Box>
      <ExtentMap
        extent={extent?.content || instanceExtent?.value}
        defaultExpanded={true}
      />
    </Box>
  );
}
