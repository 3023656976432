import React, { Fragment, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Fab from '@mui/material/Fab';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import CrossIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';

import { BACKEND_URL } from '@src/config';
import Page from '@src/components/Page';
import useAsyncStatus from '@src/hooks/useAsyncStatus';
import useCallApi from '@src/hooks/useCallApi';
import useApiClient from '@src/hooks/useApiClient';
import DocLink from '@src/components/forms/DocLink';
import * as styles from './Export.module.css';

async function checkDataExport(api, id) {
  const resp = await api.fetch(`/data-exports/${id}`);
  const res = await resp.json();
  return res
}

async function makeExport(api) {
  const resp = await api.fetch(
    '/data-exports/', {
      method: 'POST'
    }
  );
  const result = await resp.json();
  return result;
}



function StatusIcon({ status }) {
  const label = {
    SUCCESS: 'Disponible',
    ERROR: 'Erreur',
    PENDING: 'En cours',
    RUNNING: 'En cours',
  }[status];
  const Component = {
    SUCCESS: (
      <Chip color="success" icon={<CheckIcon/>} label={label}/>
    ),
    ERROR: (
      <Chip color="error" icon={<CrossIcon/>} label={label}/>
    ),
    PENDING: (
      <>
      <Chip label={label} icon={
        <CircularProgress
          size={20}
        />
        }/>
      </>
    ),
    RUNNING: (
      <>
      <Chip label={label} icon={
        <CircularProgress
          size={20}
        />
        }/>
      </>
    ),
  }[status];
  return Component;
}

function formatCreatedAt(createdAt) {
  return new Date(createdAt).toLocaleString(
    'fr-FR',
    {year:"numeric", month:"long", day:"numeric", hour: "numeric", minute: "numeric"},
  );
}

function ExportRow({ api, dataExport }) {
  const exp = useAsyncStatus(api, `/data-exports/${dataExport.id}`, dataExport);
  const createdAt = formatCreatedAt(exp.created_at);
  return (
    <Accordion disabled={exp.status !== 'SUCCESS' } className={styles.Accordion}>
      <AccordionSummary
        expandIcon={exp.status == 'SUCCESS' ? <ExpandMoreIcon/> : null}
        className={styles.Accordion}
      >
        <div className={styles.summary}>
          <StatusIcon status={exp.status}/>
          <Typography style={{ opacity: 0.8 }}>le {createdAt}</Typography>
        </div>
      </AccordionSummary>
      {exp.status == "SUCCESS" ? (
        <AccordionDetails>
          <List sx={{ pl: 4 }}>
            <ListItem
              secondaryAction={
                <Tooltip title={exp.netex_file_path} placement="right">
                  <Fab
                    variant="extended"
                    size="small"
                    onClick={() => downloadFile(api, exp.id, exp.netex_file_path)}
                  >
                    <DownloadIcon/>
                    Télécharger
                  </Fab>
                </Tooltip>
              }
            >
              <ListItemText>
                NeTEx Accessibilité
              </ListItemText>
            </ListItem>
            <Divider variant="middle"/>
            <ListItem
              secondaryAction={
                <Tooltip title={exp.planet_file_path} placement="right">
                  <Fab
                    variant="extended"
                    size="small"
                    onClick={() => downloadFile(api, exp.id, exp.planet_file_path)}
                  >
                    <DownloadIcon/>
                    Télécharger
                  </Fab>
                </Tooltip>
              }
            >
              <ListItemText>
                Walk Data Model
              </ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
}


async function downloadFile(api, id, filename) {
  const endpoint = `/data-exports/${id}/files/${filename}`;
  const resp = await api.fetch(endpoint);
  const blob = await resp.blob();
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}


export default function Export() {

  const [newExports, setNewExports] = useState([]);

  const apiClient = useApiClient();

  const dataExports = useCallApi('/data-exports/', {}) || [];

  useEffect(() => {
    setNewExports([]);
  }, [dataExports]);

  const onClickExport = async () => {
    const newExport = await makeExport(apiClient.current);
    setNewExports([newExport, ...newExports]);
  }

  return (
    <Page
      title={<Typography variant="h1">Exporter</Typography>}
    >
      <Box sx={{ m: 4}}>
        <Typography paragraph>
          Exporter vos données aux formats <a target="_blank"
            href="https://normes.transport.data.gouv.fr/normes/netex/accessibilit%C3%A9/">
            NeTEx Accessibilité
          </a> et <DocLink path='/datamodel/'>Walk Data Model</DocLink>.
        </Typography>
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography>
          <Button variant="contained" onClick={onClickExport}>
            Nouvel export
          </Button>
        </Typography>
      </Box>
      <List>
        <Fragment>
          {newExports.map((exp) => (
            <ExportRow key={exp.id} api={apiClient.current} dataExport={exp} />
          ))}
          {dataExports.map((exp) => (
            <ExportRow key={exp.id} api={apiClient.current} dataExport={exp} />
          ))}
        </Fragment>
      </List>
    </Page>
  );
}
