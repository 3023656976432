
export const OIDC_CONFIG = {
  authority: process.env.OIDC_SERVER || "http://localhost:3030/realms/yukaimaps",
  clientId: process.env.OAUTH_CLIENT_ID || "local-id",
  //clientSecret: process.env.OAUTH_CLIENT_SECRET || "Q1rTcyjwzcIkUsoIAxBL2wQ1XOZs40kW",
  redirectUri: process.env.HOME_DEPLOY_URL || "http://127.0.0.1:1234/"
}

export const EDITOR_URL = process.env.EDITOR_URL || "http://127.0.0.1:8080/"

export const BACKEND_URL = process.env.BACKEND_URL || "http://127.0.0.1:8000"
export const APK_INDEX_FILE_URL = process.env.APK_INDEX_FILE_URL || "http://127.0.0.1:7000/index.json"

export const ONLINE_DOCS_ROOT = process.env.ONLINE_DOCS_ROOT || "https://mtes-mct.github.io/alm-docs/"

export const HIDE_CONFIG = JSON.parse(process.env.HIDE_CONFIG || 'false') ? true : false;
