import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SimpleMap from '@src/components/SimpleMap';


export default function ExtentMap({ extent, defaultExpanded=false }) {
  return (
    <>
      {extent ? (
        <Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded={defaultExpanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>
              { extent?.properties?.title ?? 'Emprise' }
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SimpleMap features={extent} static />
          </AccordionDetails>
        </Accordion>
      ) : null}
    </>
  );
}
